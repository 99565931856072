import { useEffect, useState } from 'react';
import axios from 'axios';
import { apiUrl, cdnUrl, cdnParams, isDevelopment, newsletterIframeUrl } from '../Helpers/General';
import { IoLocationSharp, IoNavigate } from "react-icons/io5";

import Pricings from '../Components/Pricings';
// import Preview from '../Components/Preview';
import Loader from '../Components/Loader';


function Main(props) {

    const [isLoading,   setIsLoading] = useState(false);
    const [data, 	    setData] = useState([]);

	useEffect(() => {

        setIsLoading(true);
        axios.get(`${apiUrl}/api/items?media=1`)
            .then(res => res.data)
            .then(data => {
                setData(data);
                setIsLoading(false);
            });
	},[])

    useEffect(() => {
        // let rootElement = document.querySelector('.location.active');
        let headElements = [...document.querySelectorAll('.location__head')];

        document.addEventListener('scroll', (e) => {
            
            headElements.forEach( el => {
                if( el.closest('.location').classList.contains('active') && el.getBoundingClientRect().top === 0 ){
                    // el.classList.add('sticky');
                }else{
                    // el.classList.remove('sticky');
                }
            })
        });
    }, [data])

    const toggleLocation = (e) => {
        e.target.closest('.location').classList.toggle('active');

        setTimeout( () => {
            if( window.gtag !== undefined 
                && window.gtag !== null
                && e.target.closest('.location').classList.contains('active')
                && isDevelopment === false
            ){
                window.gtag('event', 'show_location', {
                    location: e.target.querySelector('h2 label').innerText
                });
            }
        },100);
    }

    const filteredItems = data.filter( x => parseInt(x.isActive) === 1 );

	return(
		
		<div className="main">

            <header className="header">

                <h1 className="header__title">Winter Islands</h1>
                
                <p className="header__subtitle">
                    Fine Art Photography of island life and scenery during winter season.
                </p>

                {isLoading && <Loader /> }

            </header>

            <div className="items">

                {filteredItems.map( (list, listIdx) => 

                    <div 
                        key={`list_${listIdx}`} 
                        className={`location ${(filteredItems.length === 1)?'active':''}`}
                    >

                        <div className="location__head">
                            <h2 onClick={(e) => toggleLocation(e)}>
                                <label>
                                    <IoLocationSharp />
                                    {list.title}
                                </label>
                                <span>{ list.media.length} images</span>
                            </h2>

                            <aside>
                                <label>
                                    {list.subtitle}
                                </label>
                                <span>
                                    <a href={`https://www.google.com/maps/search/?api=1&query=${list.title}`} target="_blank" rel="noreferrer">
                                        <IoNavigate />
                                    </a>
                                </span>
                            </aside>
                        </div>

                        <div className="location__items">
                            { list.media.map( (media, mediaIdx) => 
                                <div
                                    key={`media_${mediaIdx}`} 
                                    className="item" 
                                >

                                    <img 
                                        src={`${cdnUrl}${media.model_type}/${media.model_id}/${media.data}${cdnParams}&width=1000`} 
                                        alt={`${mediaIdx+1} of ${list.media.length} in ${list.title}`}
                                        loading="lazy"
                                    />

                                    <div className="item__details">
                                        {/*
                                        <span className="item__details-preview">
                                            <Preview media={media} />
                                        </span>
                                        */}
                                        <span className="item__details-pricing">
                                            <Pricings media={media} />
                                        </span>
                                        <span className="item__details-pagination">
                                            {mediaIdx+1} / {list.media.length}
                                        </span>
                                    </div>

                                </div>
                            )}
                        </div>

                    </div>

                )}

            </div>

            <footer className="footer">
                
                <p className="footer__item">
                    For custom print sizes, contact us at <a href="mailto:hello@winterislands.com">hello@winterislands.com</a>.
                </p>
                <p className="footer__item">
                    Winter Islands will contribute 1% of every purchase to removing CO<sup>2</sup> from the atmosphere.
                </p>
                <p className="footer__item">
                    Copyright Winter Islands &copy; 2021.-2023. All rights reserved.
                </p>

                <iframe 
                    title="newsletter"
                    src={newsletterIframeUrl}
                    frameborder="0"  
                    allowfullscreen 
                    style={{width: '100%', minHeight: '450px', margin: 0, padding: 0}}>
                </iframe>

            </footer>

		</div>
		
	)
		
}
	
export default Main;