import { useEffect, useState, useRef } from 'react';
import { isDevelopment } from '../Helpers/General';
import { IoCartSharp } from "react-icons/io5";


function Pricings(props) {

    const itemRef = useRef(null);

    const [pricings, setPricings] = useState([]);
    const [hasPricing, setHasPricing] = useState(false);

	useEffect(() => {
        if( props.media.pricings !== null){

            let pricingItems = Object.values(JSON.parse(props.media.pricings));
            let pricingsFiltered = pricingItems
                .filter( x => 
                    x.hasOwnProperty('active')
                    && parseInt(x.active) === 1 
                    && x.info !== ''
                );

            if( pricingsFiltered.length > 0 ){
                setHasPricing(true);
            }

            setPricings( pricingsFiltered );
        };
	},[props.media])

    const togglePricings = () => {
        itemRef.current.classList.toggle('active');

        setTimeout( () => {
            if( window.gtag !== undefined 
                && window.gtag !== null
                && itemRef.current.classList.contains('active') 
                && isDevelopment === false
            ){
                window.gtag('event', 'show_pricings', {
                    photo: JSON.stringify(props.media)
                });
            }
        },100);
    }



	return(
		
		<div className="pricings" ref={itemRef}>

            { hasPricing && 
                <button onClick={() => togglePricings()}>
                    <IoCartSharp />
                    <small hidden>
                        ({pricings.length}
                        { (pricings.length > 1) ? ' sizes' : ' size' })
                    </small>
                </button>
            }

            { pricings.map( (pricing,pricingIdx) => 
                    <a 
                        key={`pricing_${props.media.id}_${pricingIdx}`}
                        className="pricing"
                        href={pricing.info}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <label>{pricing.name}</label>
                        <strong>{pricing.price}&euro;</strong>
                    </a>
            )}

		</div>
		
	)
		
}
	
export default Pricings;