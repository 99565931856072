import Main from './Containers/Main';
import './Styles/index.scss';

function App() {
  return (
    <div className="app">
        <Main />
    </div>
  );
}

export default App;
