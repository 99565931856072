import { Fragment, useEffect } from 'react';


function Loader(props) {

	useEffect(() => {}, [])

	return(
		
		<Fragment>

            <svg  className="loader" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path fill="none" stroke="#222222" strokeWidth="3" strokeDasharray="177.0463604736328 79.54256774902345" d="M24.3 30C11.4 30 5 43.3 5 50s6.4 20 19.3 20c19.3 0 32.1-40 51.4-40 C88.6 30 95 43.3 95 50s-6.4 20-19.3 20C56.4 70 43.6 30 24.3 30z" strokeLinecap="round">
                    <animate attributeName="stroke-dashoffset" repeatCount="indefinite" dur="3.7037037037037033s" keyTimes="0;1" values="0;256.58892822265625" />
                </path>
            </svg>

		</Fragment>
		
	)
		
}
	
export default Loader;