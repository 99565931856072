const isLocal = false;

export const isDevelopment = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname.includes('192.168.') ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const baseUrl = (isLocal) 
	? 'http://localhost/cms_ml/' 
	: 'https://www.winterislands.com/';

export const apiUrl = (isLocal) 
	? `${baseUrl}`
	: `https://www.winterislands.com/cms/`;

export const previewApiUrl = (isLocal) 
	? `${baseUrl}`
	: `https://www.borisvidosevic.com/ai/winterislands-preview.php`;
/*
export const mediaUrl = (isLocal) 
	? `https://eu2.contabostorage.com/develop/`
	: `https://eu2.contabostorage.com/1fe10eb1de0d423e90a8bac8eb977288:eurokazarchive/`;
*/
export const cdnUrl = (isLocal) 
    ? `https://agewvlkkgp.cloudimg.io/v7/_develop_/`
    : `https://agewvlkkgp.cloudimg.io/v7/www.winterislands.com/cms/uploads/`;

export const cdnParams = `?force_format=jpeg`;

export const newsletterIframeUrl = 'https://af618b71.sibforms.com/serve/MUIEAFZTJHu98qitnFX9C9tBc5sx7XTpW_FwPgaoaeX187_p-AeIYxIeInChNbIicWEn025ABhp-CQ-4ccbC5D-fWcjdz5RdgStwUp_jS3lr69SogBC8P6tQ7Odu_kxnJ4Cb5O5tz9HQi_Ua5efq7CN6P2G_M5nGnZixjxkE6q4JEGnvBO6hOg9wylrq84HhhqQFRZWHOrN6lBsC';